<template>
  <div class="prices">
    <BaseHeroImg
      :imgSrc="require('@/assets/images/prices-hero.jpg')"
      imgAlt="zee"
    />
    <BaseHero titleAlignment="left"> Tarieven </BaseHero>
    <BaseAlinea class="prices__list" useBg v-if="prices">
      <template #header>Tarieven</template>
      <p v-for="p in prices" :key="p.sys.id">
        <span>{{ p.fields.titel }}:</span><br />
        € {{ p.fields.price.toString().replace(".", ",")
        }}{{ p.fields.price % 1 === 0 ? ",-" : null }}
        {{ p.fields.addon }}
      </p>
    </BaseAlinea>

    <BaseQuote
      class="prices__quote"
      quote="Geen beter kompas voor het leven dan je eigen hart"
      author="ACT-gezegde"
    />
  </div>
</template>

<script lang="ts">
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import BaseQuote from "@/components/base/BaseQuote.vue";
import { defineComponent, ref } from "vue";
import client from "@/hooks/useContentful";

export default defineComponent({
  components: { BaseHero, BaseHeroImg, BaseAlinea, BaseQuote },
  setup() {
    const prices = ref<any>(null);

    client
      // eslint-disable-next-line @typescript-eslint/camelcase
      .getEntries({ content_type: "price" })
      .then((res) => {
        prices.value = res.items;
        prices.value.sort(
          (a: any, b: any) => a.fields.position - b.fields.position
        );
      })
      .catch();

    return { prices };
  }
});
</script>

<style scoped lang="scss">
.prices {
  position: relative;
  overflow-x: hidden;

  &__list {
    padding-bottom: 22px;
  }

  &__quote {
    margin-bottom: 28px;
  }

  p {
    margin-bottom: 22px;
  }

  span {
    font-weight: bold;
  }
}
</style>
