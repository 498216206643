
import BaseHero from "@/components/base/BaseHero.vue";
import BaseHeroImg from "@/components/base/BaseHeroImg.vue";
import BaseAlinea from "@/components/base/BaseAlinea.vue";
import BaseQuote from "@/components/base/BaseQuote.vue";
import { defineComponent, ref } from "vue";
import client from "@/hooks/useContentful";

export default defineComponent({
  components: { BaseHero, BaseHeroImg, BaseAlinea, BaseQuote },
  setup() {
    const prices = ref<any>(null);

    client
      // eslint-disable-next-line @typescript-eslint/camelcase
      .getEntries({ content_type: "price" })
      .then((res) => {
        prices.value = res.items;
        prices.value.sort(
          (a: any, b: any) => a.fields.position - b.fields.position
        );
      })
      .catch();

    return { prices };
  }
});
